<template>
  <div class="mip-detail-main col-lg-8 col-md-12 col-sm-12 col-xs-12">
    <div class="mip-detail-title">
      <h1>{{ newsInfo.title }}</h1>
      <i><span class="mip-author">{{ newsInfo.classify }}</span> · {{ newsInfo.time }}</i>
    </div>
    <div class="mip-detail-content" v-html="newsInfo.content">
    </div>
  </div>
</template>

<script>
import DataList from "@/data/index";
export default {
  data() {
    return {
      articleId: 0,
      newsInfo: null,
    };
  },
  created() {
    this.articleId = parseInt(this.$route.params.id);
    this.getDetail();
  },
  methods: {
    getDetail() {
      const newsInfo = DataList.filter((item) => item.id === this.articleId)[0];
      if (typeof newsInfo !== "undefined") {
        this.newsInfo = newsInfo
      } else {
          console.log('undefined')
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.mip-detail-main {
  position: relative;
  min-height: 500px;
  padding: 20px;
  background-color: #fff;
}

.mip-detail-title h1 {
  font-size: 26px;
  color: #333;
  font-weight: 700;
}

.mip-detail-title i {
  //   height: 30px;
  line-height: 30px;
  padding: 10px 0 20px 0;
  display: block;
  color: #999;
  font-size: 14px;
  font-style: normal;
}

.mip-detail-content p {
  line-height: 32px;
  padding-bottom: 10px;
  font-size: 16px;
  color: #333;
  word-break: break-all;
}

.mip-detail-title .mip-author {
  color: #999;
}
</style>